<template>
  <div>
    <Breadcrumb activePage="個人資料" />
    <div class="profile">
      <div class="row">
        <div class="col-md-4 mb-4 mb-md-0">
          <div class="card">
            <div class="card-body profile-card pt-4 d-flex flex-column align-items-center profile__card">
              <img :src="avatarImg" alt="Profile" class="rounded-circle" ref="roundImg" style="width: 120px; height: 120px;">
              <h2 class="mt-2 profile__name">{{ user.userName }}</h2>
              <h3 class="mt-2 profile__identity">{{ user.status }}</h3>
              <!-- <div class="social-links mt-2">
                <a v-if="user.link === 'NotSet'" class="twitter" href=""><i class="fas fa-link"></i></a>
                <a v-else :href="user.link" class="twitter"><i class="fas fa-link"></i></a>
              </div> -->
              <web3_connect
                :API_URL="API_URL"
                :extra_sign_data="extra_sign_data"
                @update="updateWallet"
                @toast="toast"
                @getUser="getUser"
                :walletAddress="user.walletAddress"
                :isValidityOwner="user.isValidityOwner"
              ></web3_connect>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <div class="card profile__card">
            <div class="card-body pt-3">
              <ul class="nav nav-tabs nav-tabs-bordered tablist">

                <li class="nav-item">
                  <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-edit">編輯</button>
                </li>

                <li class="nav-item" v-if="LoginProvider == 'password'">
                  <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password">更改密碼</button>
                </li>

              </ul>
              <div class="tab-content pt-2">
                <!-- <div class="tab-pane fade show active profile-overview" id="profile-overview">
                  <h5 class="card-title text-start">個人資料</h5>

                  <div class="row mb-4">
                    <div class="col-lg-3 col-md-4 label mb-2 mb-md-0">使用者名稱</div>
                    <div class="col-lg-9 col-md-8 text-start">{{ user.userName }}</div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-lg-3 col-md-4 label mb-2 mb-md-0">身份</div>
                    <div class="col-lg-9 col-md-8 text-start">{{ user.status }}</div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-lg-3 col-md-4 label mb-2 mb-md-0">Email</div>
                    <div class="col-lg-9 col-md-8 text-start">{{ user.email }}</div>
                  </div>

                </div> -->

                  <div class="tab-pane show active fade profile-edit pt-3" id="profile-edit">

                    <form @submit.prevent="updateUser">
                      <div class="row mb-3">
                        <label for="profileImage" class="col-md-4 col-lg-3 col-form-label">圖片</label>
                        <div class="col-md-8 col-lg-9">
                          <img :src="avatarImg" alt="Profile" ref="img">
                          <div class="pt-2">
                            <input type="file" id="customFile" class="form-control d-none"
                              ref="files" @change="uploadFile">
                            <label for="customFile" class="btn btn-main btn-sm me-1">
                              <i class="fas fa-spinner fa-spin" v-if="fileUploading=='loading'"></i>
                              <i class="fas fa-upload" v-if="fileUploading=='done'"></i>
                              <i class="far fa-times-circle" v-if="fileUploading=='fail'"></i>
                              </label>
                            <template v-if="avatarImg !== '/static/images/user.png'">
                              <a class="btn btn-danger btn-sm ms-1" title="Remove my profile image" @click.prevent="deleteFile"><i class="far fa-trash-alt"></i></a>
                            </template>

                          </div>
                        </div>
                      </div>

                      <div class="row mb-3 align-items-center">
                        <label for="fullName" class="col-md-4 col-lg-3 col-form-label">帳號</label>
                        <div class="col-md-8 col-lg-9 text-start">
                          <span>{{ user.account }}</span>
                        </div>
                      </div>

                      <div class="row mb-3">
                        <label for="fullName" class="col-md-4 col-lg-3 col-form-label">使用者名稱</label>
                        <div class="col-md-8 col-lg-9">
                          <input name="fullName" type="text" class="form-control" id="fullName" v-model="nickName">
                        </div>
                      </div>

                      <div class="row mb-3 align-items-center">
                        <label for="about" class="col-md-4 col-lg-3 col-form-label">身份</label>
                        <div class="col-md-8 col-lg-9 text-start">
                          <span>{{ user.status }}</span>
                        </div>
                      </div>

                      <div class="row mb-3 align-items-center">
                        <label for="company" class="col-md-4 col-lg-3 col-form-label">Email</label>
                        <div class="col-md-8 col-lg-9 text-start">
                          <span>{{ user.email }}</span>
                        </div>
                      </div>

                      <!-- <div class="row mb-3">
                        <label for="Twitter" class="col-md-4 col-lg-3 col-form-label">相關連結</label>
                        <div class="col-md-8 col-lg-9">
                          <input name="twitter" type="text" class="form-control" id="Twitter" v-model="user.link">
                        </div>
                      </div> -->

                      <div class="text-center">
                        <button type="submit" class="btn btn-main">儲存變更</button>
                      </div>
                    </form>
                  </div>

                  <div class="tab-pane fade pt-3 profile-change-password" id="profile-change-password" v-if="LoginProvider == 'password'">
                    <ValidationObserver v-slot="{ handleSubmit }">
                      <form @submit.prevent="handleSubmit(changePsd)">
                        <div class="row mb-3">
                          <label for="currentPassword" class="col-md-4 col-lg-3 col-form-label text-start">舊密碼</label>
                          <div class="col-md-8 col-lg-9">
                            <ValidationProvider rules='required|password' v-slot='{ errors , classes }'>
                              <div :class="classes" class="position-relative">
                                <input
                                  name="password"
                                  class="form-control mb-1"
                                  id="currentPassword"
                                  required
                                  v-model="user.password"
                                  :type="showPsd ? 'text' : 'password'"
                                  placeholder="6位以上 | 含大小寫字母及數字">
                                <span class="text-danger mt-1">{{ errors[0] }}</span>
                                <i class="fas fa-eye psd_visible position-absolute"
                                  @click.prevent="showPsd=true" v-if="!showPsd"></i>
                                <i class="fas fa-eye-slash psd_invisible position-absolute"
                                  @click.prevent="showPsd=false" v-if="showPsd"></i>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="newPassword" class="col-md-4 col-lg-3 col-form-label text-start">新密碼</label>
                          <div class="col-md-8 col-lg-9">
                            <ValidationProvider rules='required|password|password2:@confirm' v-slot='{ errors , classes }'>
                              <div :class="classes" class="position-relative">
                                <input
                                  name="newpassword"
                                  class="form-control mb-1"
                                  id="newPassword"
                                  required
                                  v-model="newPsd"
                                  :type="showNewPsd ? 'text' : 'password'"
                                  placeholder="6位以上 | 含大小寫字母及數字">
                                <span class="text-danger mt-1">{{ errors[0] }}</span>
                                <i class="fas fa-eye psd_visible position-absolute"
                                  @click.prevent="showNewPsd=true" v-if="!showNewPsd"></i>
                                <i class="fas fa-eye-slash psd_invisible position-absolute"
                                  @click.prevent="showNewPsd=false" v-if="showNewPsd"></i>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="confirmPassword" class="col-md-4 col-lg-3 col-form-label text-start">確認密碼</label>
                          <div class="col-md-8 col-lg-9">
                            <ValidationProvider name="confirm" rules='required' v-slot='{ errors , classes }'>
                              <div :class="classes" class="position-relative">
                                <input
                                  name="confirmPassword"
                                  class="form-control mb-1"
                                  id="confirmPassword"
                                  required
                                  :type="showConfirmPsd ? 'text' : 'password'"
                                  v-model="confirmPsd"
                                  placeholder="6位以上 | 含大小寫字母及數字">
                                <span class="text-danger mt-1">{{ errors[0] }}</span>
                                <i class="fas fa-eye psd_visible position-absolute"
                                  @click.prevent="showConfirmPsd=true" v-if="!showConfirmPsd"></i>
                                <i class="fas fa-eye-slash psd_invisible position-absolute"
                                  @click.prevent="showConfirmPsd=false" v-if="showConfirmPsd"></i>
                              </div>
                            </ValidationProvider>
                          </div>
                        </div>

                        <div class="text-center">
                          <button type="submit" class="btn btn-main">儲存變更</button>
                        </div>
                      </form>
                    </ValidationObserver>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Meta :title ="title" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Breadcrumb from '../components/Breadcrumb';
import web3_connect from '../components/web3_connect';

const Swal = require('sweetalert2');

export default {
  name: 'Profile',
  components: {
    Breadcrumb,
    web3_connect,
  },
  data() {
    return {
      user: {
        account: 'Kevin Anderson',
        userName: 'Kevin Anderson',
        status: '教師',
        email: 'k.anderson@example.com',
        link: 'https://twitter.com/#',
        password: '',
        getNotice: true,
        walletAddress: '',
        isValidityOwner: false,
      },
      newPsd: '',
      confirmPsd: '',
      showPsd: false,
      showNewPsd: false,
      showConfirmPsd: false,
      fileUploading: 'done',
      avatarImg: '',
      nickName: '',
      API_URL: 'https://api1.metalearning.com.tw/Member/Web3',
      extra_sign_data: '測試額外資料',
      wallet: {
        web3: null,
        account: null,
        provider: null,
        response: null,
      },
      title: '個人資料 - 元學堂',
    };
  },
  metaInfo: {
    title: "個人資料 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '個人資料 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    changePsd() {
      const data = {
        OldPassword: this.user.password,
        NewPassword: this.newPsd,
      };
      this.$http.post(`${this.$API_PATH}/Member/ChangePassword`, data, {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.$log.debug(res.data);
        Swal.fire(
          '',
          `${res.data.message}`,
          'success',
        );
      }).catch((err) => {
        if (err.response.status == 400 || err.response.status == 401) {
          Swal.fire(
            '',
            '修改失敗，請稍後再試',
            'info',
          );
        }
        this.$log.debug(err.response);
      });
    },
    getUser() {
      const vm = this;
      this.updateLoading(true);
      vm.$http.get(`${this.$API_PATH}/Member/Profile`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${vm.userAuth}`,
        },
      }).then((res) => {
        // let n = {
        //   userName: "管理員測試2",
        //   avatar: "https://imgedu.drmaster.mypeoplevol.com/i/fs/Avatar/20220524/16534044411fd7ab39479648f1852295a4dd37e487.jpg",
        //   email: "drAdmin@test.com",
        //   account: "drAdmin",
        //   relatedLink: "https://example.com",
        //   web3: {
        //     "walletAddress": "eteteppspspspxpcpffpfspspspspwpldl0d0pccpc",
        //     "isValidityOwner": true
        //   },
        //   roles: [
        //     "系統管理員"
        //   ]
        // }
        vm.user.account = res.data.data.account;
        vm.user.userName = res.data.data.userName;
        vm.user.email = res.data.data.email;
        vm.user.link = res.data.data.relatedLink;
        vm.user.status = res.data.data.roles[0];
        vm.nickName = res.data.data.userName;
        vm.user.walletAddress = res.data.data.web3.walletAddress;
        vm.user.isValidityOwner = res.data.data.web3.isValidityOwner;
        this.updateLoading(false);
        // console.log(vm.user);
      }).catch((err) => {
        // this.$router.push('/error');
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 401 || err.response.status == 400) {
            Swal.fire(
              '',
              `${err.response.data.message}`,
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          } else if (err.response.status == 404) {
            Swal.fire(
              '',
              '找不到此課程',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                this.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '錯誤',
            '請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              this.$router.push('/');
            }
          });
        }
      });
    },
    updateUser() {
      const vm = this;
      const data = {
        userName: vm.nickName,
        avatarUrl: vm.avatarImg == '/static/images/user.png' ? '' : vm.avatarImg,
      };
      vm.$http.put(`${this.$API_PATH}/Member/Profile`, data, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${vm.userAuth}`,
          'Content-Type': 'application/json',
        },
      }).then((res) => {
        const data = { avatar: res.data.data.avatar };
        this.$store.dispatch('updateProfile', data);
        Swal.fire(
          '',
          '儲存完成',
          'success',
        );
        vm.getUser();
      }).catch((err) => {
        if (err.response.status == 400 || err.response.status == 401) {
          Swal.fire(
            '',
            `${err.response.data.message}`,
            'info',
          );
        }
        // this.$router.push('/error');
        this.$log.debug(err.response);
      });
    },
    uploadFile() {
      const uploadedFile = this.$refs.files.files[0];
      this.$log.debug(uploadedFile);
      const vm = this;
      const formData = new FormData();
      formData.append('upload', uploadedFile);
      vm.fileUploading = 'loading';
      vm.$http.post(`${vm.$API_IMGPATH}/Avatar`, formData)
        .then((response) => {
          this.$log.debug(response.data);
          vm.$refs.img.src = `${response.data.url}`;
          vm.$refs.roundImg.src = `${response.data.url}`;
          vm.avatarImg = response.data.url;
          vm.fileUploading = 'done';
        }).catch((err) => {
          vm.fileUploading = 'fail';
          this.$log.debug(err.response);
        });
    },
    deleteFile() {
      this.avatarImg = '/static/images/user.png';
    },
    updateWallet(val) {
      this.wallet = val;
    },
    toast() {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
      Toast.fire({
        icon: 'success',
        title: '複製完成',
      });
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  created() {
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.userAuth);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
    this.getUser();
    this.avatarImg = this.Avatar;
  },
};
</script>

<style lang="scss" scoped>
  .invalid .text-danger {
    text-align: left;
    width: 100%;
    display: block;
    font-size: 12px;
  }

  .invalid input {
    border: 1px #EB0600 solid;
    &:focus {
      box-shadow: 0 0 0 0.25rem rgba(252, 67, 21, 0.25);
    }
  }
</style>
